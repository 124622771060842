import React, { createContext, PropsWithChildren, useCallback, useState } from 'react';

import LoadingOverlay from '../common/components/Loading/LoadingOverlay';

export type LoaderContextType = {
    isLoading: boolean;
    show: () => void;
    hide: () => void;
    message: (message: string) => void;
};

export const LoaderContext = createContext<LoaderContextType>({} as LoaderContextType);

function LoaderProvider({ children }: PropsWithChildren<{}>) {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('')

    const show = useCallback(() => {
        setIsLoading(true);
    }, []);

    const hide = useCallback(() => {
        setIsLoading(false);
    }, []);

    const setMessageText = useCallback((newMessage: string) => {
        setMessage(newMessage);
    }, []);

    return (
        <LoaderContext.Provider value={{ hide, show, isLoading, message: setMessageText }}>
            <LoadingOverlay loading={isLoading} message={message} />
            {children}
        </LoaderContext.Provider>
    );
}

export default LoaderProvider;
