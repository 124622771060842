import { useEffect, useRef, useState } from 'react';

function useCountdownTimer(options?: { initialSeconds?: number }) {
    const timerId = useRef<NodeJS.Timeout>();

    const [isCounting, setIsCounting] = useState(!!options?.initialSeconds);
    const [remainingTime, setRemainingTime] = useState(
        options?.initialSeconds ?? 0
    );

    useEffect(() => {
        if (options && options.initialSeconds) {
            startCountdown(options.initialSeconds);
        }

        return () => clearInterval(timerId.current!);
    }, []);

    const startCountdown = (seconds: number) => {
        clearInterval(timerId.current!);
        setIsCounting(true);
        setRemainingTime(seconds);

        timerId.current = setInterval(
            (() => {
                let _remainingTime = seconds;

                return () => {
                    if (_remainingTime === 0) {
                        clearInterval(timerId.current!);
                        setIsCounting(false);

                        return;
                    }

                    setRemainingTime(--_remainingTime);
                };
            })(),
            1000
        );
    };

    const stopCounting = () => {
        clearInterval(timerId.current!);
    };

    return {
        isCounting,
        remainingTime,
        startCountdown,
        stopCounting
    };
}

export default useCountdownTimer;
