import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router';
import images from '../../assets/images';

function Dashboard({ children }: PropsWithChildren<{}>) {
    return (
        <React.Fragment>
            <div
                className="font-prompt flex h-screen w-screen flex-col"
                style={{
                    backgroundImage: `url(${images.ESIGNATURE_BG})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center'
                }}
            >
                <Outlet />
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
