import React, { useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { twMerge } from 'tailwind-merge';
import { BsImage, BsPen } from 'react-icons/bs';

import DigitalSignViewModel from './DigitalSign.viewmodel';

function DigitalSignView() {
    const {
        signRef,
        signType,
        imageBase64,
        selectedImage,
        companyPosition,

        handleClear,
        handleSubmit,
        handleImageChange,
        setCompanyPosition,
        handleSignTypeChange,
        handleGetImageConfig
    } = DigitalSignViewModel();

    const signPadContainerRef = useRef<HTMLDivElement>(null);
    const imagePickerRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const onResize = () => {
            if (signType === 'pen') {
                const signPadContainer = signPadContainerRef.current;
                const signPad = signRef.current;
                if (!signPadContainer || !signPad) {
                    return;
                }

                signPad.getCanvas().style.display = 'none';

                const canvas = signPad.getCanvas();
                canvas.style.display = 'none';

                const clientRect = signPadContainer.getBoundingClientRect();
                canvas.width = clientRect.width;
                canvas.height = clientRect.height;
                canvas.style.display = 'block';
            }
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, [signType]);

    return (
        <div className="mt-5 flex h-[85vh] max-w-[700px] flex-col rounded-md bg-white px-4 py-2 drop-shadow-md md:max-h-[420px] md:w-5/6 md:self-center lg:m-0 lg:mt-5 lg:py-4">
            <div className="flex">
                <div className="flex-1">
                    <p className="mb-2 text-2xl font-medium">
                        Digital Signature
                    </p>
                    <span>
                        Company Position : {''}
                        <input
                            value={companyPosition}
                            className="rounded-sm border px-1"
                            onChange={e => {
                                setCompanyPosition(e.target.value);
                            }}
                        />
                    </span>
                    <div className="mr-2 mt-3 flex flex-row border-b">
                        <div
                            className={twMerge(
                                'flex items-center gap-2 p-2',
                                signType === 'pen'
                                    ? 'rounded-t-lg border-l border-r border-t border-stone-300 text-[#0081C9]'
                                    : ''
                            )}
                            onClick={() => handleSignTypeChange('pen')}
                        >
                            {/* <BsPen /> */}
                            <span>Pen Sign</span>
                        </div>
                        <div
                            className={twMerge(
                                'flex items-center gap-2 p-2',
                                signType === 'picture'
                                    ? 'rounded-t-lg border-l border-r border-t border-stone-300 text-[#0081C9]'
                                    : ''
                            )}
                            onClick={() => {
                                handleSignTypeChange('picture');
                                handleGetImageConfig();
                            }}
                        >
                            {/* <BsImage /> */}
                            <span>Picture Sign</span>
                        </div>
                    </div>
                </div>
                <div className="mt-2 flex flex-col justify-end gap-x-2 gap-y-2 self-center md:hidden">
                    <button
                        className="rounded-full bg-green-600 px-4 py-1 text-white"
                        onClick={handleSubmit}
                    >
                        <span>Submit</span>
                    </button>
                    {signType === 'pen' && (
                        <button
                            className="rounded-full border border-gray-300 px-4 py-1 text-slate-600"
                            onClick={handleClear}
                        >
                            <span>Undo</span>
                        </button>
                    )}
                </div>
            </div>

            <div className="mt-2 flex flex-1 flex-col">
                <div
                    className="flex flex-1 flex-col"
                    ref={signPadContainerRef}
                >
                    {signType === 'pen' ? (
                        <SignatureCanvas
                            clearOnResize
                            canvasProps={{
                                className: 'border',
                                width: 100,
                                height: 200
                            }}
                            ref={signRef}
                        />
                    ) : (
                        <div className="flex flex-1 flex-col items-center justify-center gap-4 rounded-sm border">
                            {imageBase64 ? (
                                <img
                                    src={imageBase64}
                                    alt="Signature-Image"
                                    className="h-[200px]"
                                />
                            ) : (
                                <React.Fragment>
                                    <input
                                        ref={imagePickerRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="hidden"
                                    />
                                    <button
                                        className="rounded-md bg-green-600 px-4 py-2 text-white"
                                        onClick={() =>
                                            imagePickerRef.current?.click()
                                        }
                                    >
                                        <span>Choose Image</span>
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="mt-2 hidden justify-end gap-x-2 md:flex">
                {signType === 'pen' && (
                    <button
                        className="border-state-200 borderpx-4 rounded-full border px-4  text-slate-600"
                        onClick={handleClear}
                    >
                        <span>Undo</span>
                    </button>
                )}
                <button
                    className="rounded-full bg-green-600 px-4 py-2 text-white"
                    onClick={handleSubmit}
                >
                    <span>Submit</span>
                </button>
            </div>
        </div>
    );
}

export default DigitalSignView;
