import axios from 'axios';
import {
    LogImageParams,
    ResponseImageConfig,
    ResponseLogimage,
    SaveImageParams,
    SignDigitalBody
} from '../schemas/signImage.schema';

export async function putSaveImageToLog(body: SaveImageParams) {
    return axios.post<string>('/v1/savesignimage', body, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function retrieveGetImageLog(params: LogImageParams) {
    return axios.get<ResponseLogimage>('/v1/getimagelog', {
        params
    });
}

export async function retrieveGetImageConfig(params: LogImageParams) {
    return axios.get<ResponseImageConfig>('/v1/getimageconfig', {
        params
    });
}

export async function putSignDigitalSignature(body: SignDigitalBody) {
    return axios.post<string>('/v1/signdigitalsignature', body, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
