import {
    DatasourceReturnType,
    ResponseError
} from '../../../models/common/respone.model';
import { putCompanyPositionLog } from '../datasources/esignatureLog.datasource';
import { CompanyPositionBody } from '../schemas/esignatureLog.schema';

export async function updateCompanyPosition(
    body: CompanyPositionBody
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await putCompanyPositionLog(body)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}
