import React from 'react';
import OverlayPortal from './OverlayPortal';
import gifs from '../../../assets/gifts';

interface Props {
    loading: boolean;
    message: string
}

function LoadingOverlay({ loading, message }: Props) {
    return (
        <OverlayPortal>
            <div className="container">
                {loading && (
                    <div className="flex flex-col justify-center items-center fixed bg-[rgb(247_247_247_/_83%)] z-[1051] top-0 left-0 w-screen h-screen">
                        <img src={gifs.FOCUSONE_LOADING} alt="my-gif" className="w-60 h-60" />
                        {/* <div className="animate-spin w-16 h-16 border-8 border-t-[#3d5af1] border-r-transparent border-b-[#3d5af1] border-l-transparent rounded-[50%]" /> */}
                        {message !== '' &&
                            <span className='text-black font-normal leading-4 text-[20px]'>
                                {message}
                            </span>
                        }
                    </div>
                )}
            </div>
        </OverlayPortal>
    );
}

export default LoadingOverlay;
