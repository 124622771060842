import React from 'react';
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';
import OtpRedirectView from '../presentations/OtpRedirect/OtpRedirect.view';
import DigitalSignView from '../presentations/DigitalSign/DigitalSign.view';
import Dashboard from '../common/components/Dashboard';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const routerOptions = { basename: baseUrl! };

const router = createBrowserRouter(
    createRoutesFromElements(
        <React.Fragment>
            <Route path='/' element={<Dashboard />}>
                <Route path='/otp' element={<OtpRedirectView />} />
                <Route path='/digitalsign' element={<DigitalSignView />} />
            </Route>
        </React.Fragment>
    ),
    routerOptions
);

export default router;
