import React from 'react';

import OtpRedirectViewModel from './OtpRedirect.viewmodel';
import OtpInput from 'react-otp-input';
import gifts from '../../assets/gifts';

function OtpRedirectView() {
    const {
        otp,
        refKey,
        refOtp,
        emailTo,
        isCounting,
        remainingTime,

        submit,
        setOtp,
        requestOtp
    } = OtpRedirectViewModel();

    return (
        <div className="flex flex-1 flex-col items-center justify-center">
            <div className="shadow-2xl">
                <div className="flex flex-col gap-2 rounded-t-lg bg-white px-10 py-2">
                    <span className="text-center text-[20px] font-medium">
                        Digital Signature
                    </span>
                    <div className="border-b-2 border-slate-100" />
                    <div className="flex flex-col items-center justify-center">
                        <div className="flex flex-col text-center">
                            <span>
                                ระบบได้ทำการส่ง OTP ไปที่{' '}
                                {emailTo ? (
                                    <span>
                                        อีเมล์ของคุณ{' '}
                                        <span className="text-[#0081C9]">
                                            {emailTo}
                                        </span>
                                    </span>
                                ) : (
                                    'Line FocusOne Official Account'
                                )}
                            </span>
                            <span>
                                เมื่อได้รับ OTP ให้ระบุเพื่อเป็นการยืนยันตัวตน
                            </span>
                            {refOtp && (
                                <div>
                                    <span>{refOtp}</span>
                                </div>
                            )}
                            <span>
                                เลขที่อ้างอิงการขอ OTP :{' '}
                                <span className="font-medium">{refKey}</span>
                            </span>
                        </div>
                        <div className="mt-5 flex flex-1 gap-2">
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                inputType="tel"
                                renderInput={props => (
                                    <input
                                        {...props}
                                        className="mr-2 h-10 w-10 rounded border text-center"
                                    />
                                )}
                            />
                            <button
                                className="ml-5 rounded-full border-slate-500 bg-green-600 px-4 py-2 text-white"
                                onClick={submit}
                            >
                                Submit
                            </button>
                        </div>
                        <div className="mt-6 flex w-full flex-1 flex-row justify-between border-t-2 py-3">
                            <span className="flex flex-row gap-2">
                                This OTP will expire in{' '}
                                {Math.floor(remainingTime / 60)}:
                                {(remainingTime % 60)
                                    .toString()
                                    .padStart(2, '0')}{' '}
                                minutes
                            </span>
                            <button
                                className={`${
                                    isCounting === false
                                        ? 'text-[#0081C9]'
                                        : 'text-gray-300'
                                }`}
                                disabled={isCounting}
                                onClick={() => {
                                    requestOtp();
                                }}
                            >
                                Resend OTP {'>'}
                            </button>
                        </div>
                        {/* <input
                            className="h-10 w-20 border text-center"
                            maxLength={6}
                            onChange={e => {
                                setOtp(e.target.value);
                            }}
                        /> */}

                        {/* {startCountdown === true &&
                            <span>Redirect In: {seconds}</span>
                        } */}
                        {/* <div className="mt-4 space-x-4">
                            {checkTimeRequestOtp === false && (
                                <button
                                    className="rounded-md border-slate-500 bg-slate-200 px-4 py-2 text-slate-600"
                                    onClick={() => {
                                        requestOtp();
                                        setCheckTimeRequestOtp(true);
                                    }}
                                >
                                    ขอ otp ใหม่อีกครั้ง
                                </button>
                            )}
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OtpRedirectView;
