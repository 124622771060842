import Swal from 'sweetalert2';
import { useSearchParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import useLoader from '../../hook/useLoader';
import {
    getImageConfig,
    saveImageToLog,
    signDigitalSignature
} from '../../data/signImage/repositories/signImage.repository';
import {
    getCheckReserveOtpLog,
    getVerifyQueryString
} from '../../data/otpRedirect/repositories/otpRedirect.repository';
import { updateCompanyPosition } from '../../data/esignatureLog/repositories/esignatureLog.repositories';

function DigitalSignViewModel() {
    const loading = useLoader();
    const [searchParams] = useSearchParams();
    const Id = searchParams.get('id');
    const mode = searchParams.get('mode');
    const hashKey = searchParams.get('key');

    const [uuid, setUuid] = useState<string>();
    const signRef = useRef<SignatureCanvas | null>(null);
    const [statusOtp, setStatusOtp] = useState<string>();
    const [imageBase64, setImageBase64] = useState<string>();
    const [signType, setSignType] = useState<string | null>('pen');
    const [companyPosition, setCompanyPosition] = useState<string>('');
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    useEffect(() => {
        const getUuid = async () => {
            try {
                loading.show();
                setUuid(Id!);
                if (mode !== 'mode1') {
                    const [, response] = await getVerifyQueryString({
                        id: Id!,
                        hashKey: hashKey!
                    });
                    setUuid(response.uuid);

                    if (response.uuid === null) {
                        showErrorAlert(
                            'ขออภัย ข้อมูล ID ไม่ถูกต้อง โปรดลองอีกครั้ง'
                        );
                    }
                    if (response.verify_hash_key === 'error') {
                        showErrorAlert(
                            'ขออภัย ข้อมูล Key ไม่ถูกต้อง โปรดลองอีกครั้ง'
                        );
                    }
                    if (response.otp_status === 'success') {
                        setStatusOtp(response.otp_status);
                        showErrorAlert(
                            'ขออภัย รายการนี้ได้ถูกดำเนินการไปเรียบร้อยแล้ว'
                        );

                        return;
                    }
                }
            } catch {
                return;
            } finally {
                loading.hide();
            }
        };

        const showErrorAlert = async (errorMessage: string) => {
            const result = await Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Ok!'
            });

            if (result.isConfirmed) {
                await new Promise(resolve => setTimeout(resolve, 100));
                window.close();
            }
        };

        getUuid();
    }, [Id !== undefined, hashKey !== undefined]);

    useEffect(() => {
        loading.show();
        if (uuid !== undefined && statusOtp !== 'success') {
            checkUrlRedirect();
        }
        const getImageSign = async () => {
            const [, response] = await getImageConfig({ otp_id: Id! });

            setCompanyPosition(response.company_position);

            if (
                response.image_signature !== '' &&
                response.image_signature !== null
            ) {
                setImageBase64(
                    `data:image/jpeg;base64,${response.image_signature}`
                );
                setSignType('picture');
            }
        };

        getImageSign();
        loading.hide();
    }, [uuid, statusOtp, uuid !== undefined]);

    const checkUrlRedirect = async () => {
        loading.show();

        const [, data] = await getCheckReserveOtpLog({
            id: Id!,
            uuid: uuid!,
            type: ''
        });

        if (data !== null) {
            const timeDifSec = reCheckTimeExpire(data.created_on);

            if (timeDifSec > 900) {
                Swal.fire({
                    title: 'Error!',
                    text: 'ขออภัย ลิงค์หมดอายุ โปรดลองอีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'Ok!'
                }).then(async result => {
                    if (result.isConfirmed) {
                        await new Promise(resolve => setTimeout(resolve, 100));
                        window.close();
                    }
                });
            }
        }
        loading.hide();
    };

    const reCheckTimeExpire = (dateTime: string): number => {
        const dateTimeNow = new Date(new Date().toISOString()).getTime();
        const createdOn = new Date(dateTime).getTime();

        const timeDifferenceSec = (dateTimeNow - createdOn) / 1000;

        return timeDifferenceSec;
    };

    const handleClear = () => {
        if (signRef.current) {
            signRef.current.clear();
        }
    };

    const handleSubmit = async () => {
        try {
            loading.show();
            let image = '';
            let type_sign = signType;
            const condition: boolean | null = signRef.current && signRef.current.getTrimmedCanvas().width === 1 && signRef.current.getTrimmedCanvas().height === 1;

            if (condition || signType === 'picture' && !selectedImage === null) {
                Swal.fire({
                    icon: 'warning',
                    title: 'ไม่พบ ลายเซ็นต์',
                    text: 'ไม่พบ ลายเซ็นต์ กรุณาเซ็นต์ ลายเซ็นต์'
                });

                return;
            }

            if (signRef.current) {
                const dataUrl = signRef.current
                    .getTrimmedCanvas()
                    .toDataURL('image/png');
                image = dataUrl;
            } else if (signType === 'picture') {
                image = selectedImage || '';
                type_sign = selectedImage ? 'picture_upload' : signType;

                await updateCompanyPosition({
                    uuid: uuid!,
                    companyPosition: companyPosition
                });
            }

            const [, response] = await saveImageToLog({
                image,
                otp_id: Id!,
                type_sign: type_sign!,
                hash_key: hashKey!
            });

            if (response === 'success' && mode !== 'mode1') {
                window.location.replace(
                    `/otp?id=${Id}&key=${hashKey}&mode=${mode}`
                );
            } else if (response === 'success' && mode === 'mode1') {
                const [, responseSign] = await signDigitalSignature({
                    id: Id!,
                    hashKey: hashKey!
                });
                window.location.replace(responseSign);
            }
        } catch (ex) {
            throw ex;
        } finally {
            loading.hide();
        }
    };

    const handleSignTypeChange = (signType: string) => {
        setSignType(prevSignType =>
            prevSignType === signType ? null : signType
        );
    };

    const handleGetImageConfig = async () => {
        loading.show();
        try {
            const [, response] = await getImageConfig({ otp_id: Id! });
            if (
                response.image_signature === '' ||
                response.image_signature === null
            ) {
                loading.hide();
                Swal.fire({
                    title: 'Fail Image',
                    text: 'ไม่มีรูปในระบบ กรุณาอัพโหลดรูป',
                    icon: 'warning',
                    confirmButtonText: 'Ok!'
                });
            }
            setImageBase64(
                `data:image/jpeg;base64,${response.image_signature}`
            );
        } catch {
            Swal.fire({
                title: 'Fail Image',
                text: 'ไม่มีรูปในระบบ กรุณาอัพโหลดรูป',
                icon: 'warning',
                confirmButtonText: 'Ok!'
            });
        } finally {
            loading.hide();
        }
    };

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result as string;
                setImageBase64(base64String);
                setSelectedImage(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    return {
        signRef,
        signType,
        imageBase64,
        selectedImage,
        companyPosition,

        handleClear,
        handleSubmit,
        handleImageChange,
        setCompanyPosition,
        handleSignTypeChange,
        handleGetImageConfig
    };
}

export default DigitalSignViewModel;
