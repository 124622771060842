import axios from 'axios';

import {
    VerifyOtpParams,
    ResponseOtpLog,
    ResponseGenerateOtp,
    CheckReserveOtpLog,
    VerifyQueryStringParams,
    ResponseUuid
} from '../schemas/otpRedirect.schema';

export async function retrieveGenerateOtp(params: CheckReserveOtpLog) {
    return axios.get<ResponseGenerateOtp>('/v1/updategenotp', {
        params
    });
}

export async function retrieveCheckReserveOtpLog(params: CheckReserveOtpLog) {
    return axios.get<ResponseOtpLog>('/v1/checkreserveotp', {
        params
    });
}

export async function retrieveVerifyOtp(params: VerifyOtpParams) {
    return axios.get<string>('/v1/verifyotp', {
        params
    });
}

export async function retrieveVerifyQueryString(
    params: VerifyQueryStringParams
) {
    return axios.get<ResponseUuid>('/v1/getverifyquerystring', {
        params
    });
}
