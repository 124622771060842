import './index.css';

import React from 'react';
import { RouterProvider } from 'react-router';

import router from './routes/Routes';
import LoaderProvider from './context/LoaderProvider';

function App() {
    return (
        <LoaderProvider>
            <RouterProvider router={router} />
        </LoaderProvider>
    )
}

(App as React.FC).displayName = App.name;
export default App;
