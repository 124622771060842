import {
    DatasourceReturnType,
    ResponseError
} from '../../../models/common/respone.model';
import {
    putSaveImageToLog,
    putSignDigitalSignature,
    retrieveGetImageConfig,
    retrieveGetImageLog
} from '../datasources/signImage.datasource';
import {
    LogImageParams,
    ResponseImageConfig,
    ResponseLogimage,
    SaveImageParams,
    SignDigitalBody
} from '../schemas/signImage.schema';

export async function saveImageToLog(
    body: SaveImageParams
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const response = (await putSaveImageToLog(body)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function getImageToLog(
    params: LogImageParams
): Promise<DatasourceReturnType<ResponseError, ResponseLogimage>> {
    try {
        const response = (await retrieveGetImageLog(params)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function getImageConfig(
    params: LogImageParams
): Promise<DatasourceReturnType<ResponseError, ResponseImageConfig>> {
    try {
        const response = (await retrieveGetImageConfig(params)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function signDigitalSignature(
    body: SignDigitalBody
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const response = (await putSignDigitalSignature(body)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}
