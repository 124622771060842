import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useLoader from '../../hook/useLoader';
import {
    getCheckReserveOtpLog,
    getGenerateOtp,
    getVerifyQueryString,
    getVerify
} from '../../data/otpRedirect/repositories/otpRedirect.repository';
import useCountdownTimer from '../../hook/useCountdownTimer';

function OtpRedirectViewModel() {
    const loading = useLoader();
    const [searchParams] = useSearchParams();
    const [otp, setOtp] = useState<string>('');
    const [uuid, setUuid] = useState<string>();
    const [refKey, setRefKey] = useState<string>();
    const [refOtp, setRefOtp] = useState<string>();
    const [emailTo, setEmailTo] = useState<string>();
    const [statusOtp, setStatusOtp] = useState<string>();

    const id = searchParams.get('id');
    const hashKey = searchParams.get('key');
    const mode = searchParams.get('mode');

    useEffect(() => {
        const getUUid = async () => {
            try {
                loading.show();
                const [, response] = await getVerifyQueryString({
                    id: id!,
                    hashKey: hashKey!
                });
                setUuid(response.uuid);

                if (response.uuid === null) {
                    showErrorAlert(
                        'ขออภัย ข้อมูล ID ไม่ถูกต้อง โปรดลองอีกครั้ง'
                    );
                }
                if (response.verify_hash_key === 'error') {
                    showErrorAlert(
                        'ขออภัย ข้อมูล Key ไม่ถูกต้อง โปรดลองอีกครั้ง'
                    );
                }
                if (response.otp_status === 'success') {
                    setStatusOtp(response.otp_status);
                    showErrorAlert(
                        'ขออภัย รายการนี้ได้ถูกดำเนินการไปเรียบร้อยแล้ว'
                    );

                    return;
                }
            } catch {
                return;
            } finally {
                loading.hide();
            }
        };

        const showErrorAlert = async (errorMessage: string) => {
            const result = await Swal.fire({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Ok!'
            });

            if (result.isConfirmed) {
                await new Promise(resolve => setTimeout(resolve, 100));
                window.close();
            }
        };

        getUUid();
    }, [id]);

    useEffect(() => {
        if (uuid !== undefined && statusOtp !== 'success') {
            checkUrlRedirect();
        }
    }, [uuid, statusOtp]);

    const { isCounting, remainingTime, startCountdown } = useCountdownTimer({
        initialSeconds: 120
    });

    const checkUrlRedirect = async () => {
        loading.show();

        const [, data] = await getCheckReserveOtpLog({
            id: id!,
            uuid: uuid!,
            type: mode!
        });

        if (data !== null) {
            const timeDifSec = reCheckTimeExpire(data.created_on);

            if (timeDifSec > 900) {
                Swal.fire({
                    title: 'Error!',
                    text: 'ขออภัย ลิงค์หมดอายุ โปรดลองอีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'Ok!'
                }).then(async result => {
                    if (result.isConfirmed) {
                        await new Promise(resolve => setTimeout(resolve, 100));
                        window.close();
                    }
                });
            } else {
                const [, response] = await getGenerateOtp({
                    id: id!,
                    uuid: uuid!,
                    type: mode!
                });

                setRefKey(response.ref_key);
                setEmailTo(response.email);
                setRefOtp(response.ref_otp);
            }
        }
        loading.hide();
    };

    const reCheckTimeExpire = (dateTime: string): number => {
        const dateTimeNow = new Date(new Date().toISOString()).getTime();
        const createdOn = new Date(dateTime).getTime();

        const timeDifferenceSec = (dateTimeNow - createdOn) / 1000;

        return timeDifferenceSec;
    };

    const requestOtp = async () => {
        loading.show();

        let selectedTypeOtp: string;
        const [, response] = await getGenerateOtp({
            id: id!,
            uuid: uuid!,
            type: 'resend'
        });

        setRefKey(response.ref_key);
        setEmailTo(response.email);
        setRefOtp(response.ref_otp);

        const resendOtpOption = new Promise(resolve => {
            resolve({
                email: 'Email',
                line: 'Line'
            });
        });

        if (response.ref_otp === null) {
            Swal.fire({
                title: 'Option!',
                text: 'ส่งรหัส otp ใหม่ไปที่',
                input: 'radio',
                inputOptions: resendOtpOption,
                icon: 'info',
                confirmButtonText: 'Resend!',
                inputValidator: value => {
                    selectedTypeOtp = value;
                }
            }).then(async result => {
                if (result.isConfirmed) {
                    loading.show();
                    const [, response] = await getGenerateOtp({
                        id: id!,
                        uuid: uuid!,
                        type: selectedTypeOtp
                    });
                    setRefKey(response.ref_key);
                    setEmailTo(response.email);
                    setRefOtp(response.ref_otp);
                    loading.hide();
                }
            });
        }

        startCountdown(120);

        loading.hide();
    };

    // const [seconds, setSeconds] = useState(5);
    // const [startCountdown, setStartCountdown] = useState(false);
    // const [urlRedirect, setUrlRedirect] = useState<string>('');

    // useEffect(() => {
    //     if (startCountdown) {
    //         const intervalId = setInterval(() => {
    //             if (seconds > 0) {
    //                 setSeconds(prevSeconds => prevSeconds - 1);
    //             } else {
    //                 clearInterval(intervalId);
    //             }
    //         }, 1000);

    //         return () => {
    //             clearInterval(intervalId);
    //         };
    //     }
    // }, [seconds, startCountdown, urlRedirect, uuid, hashKey]);

    // if (seconds === 0) {
    //     window.location.replace(urlRedirect);
    // }

    const submit = async () => {
        try {
            loading.show();
            const [, response] = await getVerify({
                id: id!,
                uuid: uuid!,
                otp: otp,
                refkey: refKey
            });

            // setUrlRedirect(response);
            response && loading.hide();
            if (response === 'error') {
                Swal.fire({
                    title: 'OTP',
                    text: `ยืนยัน OTP ผิดพลาดโปรดลองใหม่อีกครั้ง`,
                    icon: 'error',
                    confirmButtonText: 'Ok!'
                });

                return;
            }
            loading.message('ระบบกำลังนำทาง กรุณารอสักครู่...');
            loading.show();
            window.location.replace(response);
        } catch {
            loading.hide();
        }
    };

    return {
        otp,
        refKey,
        refOtp,
        emailTo,
        isCounting,
        remainingTime,
        // seconds,
        // startCountdown,

        submit,
        setOtp,
        requestOtp
    };
}

export default OtpRedirectViewModel;
