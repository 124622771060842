import {
    DatasourceReturnType,
    ResponseError
} from '../../../models/common/respone.model';
import {
    retrieveCheckReserveOtpLog,
    retrieveGenerateOtp,
    retrieveVerifyQueryString,
    retrieveVerifyOtp
} from '../datasources/otpRedirect.datasource';
import {
    VerifyOtpParams,
    ResponseOtpLog,
    ResponseGenerateOtp,
    CheckReserveOtpLog,
    VerifyQueryStringParams,
    ResponseUuid
} from '../schemas/otpRedirect.schema';

export async function getGenerateOtp(
    params: CheckReserveOtpLog
): Promise<DatasourceReturnType<ResponseError, ResponseGenerateOtp>> {
    try {
        const data = (await retrieveGenerateOtp(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getCheckReserveOtpLog(
    params: CheckReserveOtpLog
): Promise<DatasourceReturnType<ResponseError, ResponseOtpLog>> {
    try {
        const data = (await retrieveCheckReserveOtpLog(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getVerify(
    params: VerifyOtpParams
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await retrieveVerifyOtp(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getVerifyQueryString(
    params: VerifyQueryStringParams
): Promise<DatasourceReturnType<ResponseError, ResponseUuid>> {
    try {
        const data = (await retrieveVerifyQueryString(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}
